/**
 * Cards Plane
 */

.cards-plane {
  .card-header-title {
    color: #00697E;
    font-weight: 600;
    line-height: 20px;
    font-size: 20px;
    letter-spacing: -0.05em;
  }

  .card-header-subtitle {
    color: #979797;
    font-size: 16px;
    letter-spacing: -0.05em;
    margin: 13px 0 0;
    display: block;
  }

  .card-supply {
    padding: 16px 0 0;
    margin-left: 78px;
  }

  .card-supply-info {
    color: #2E363C;
    font-size: 16px;
    text-transform: uppercase;
  }

  .card-supply-title {
    font-size: 12px;
  }

}
