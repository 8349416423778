/**
 * Navigation
 */


/**
 * Main menu
 */
.main-menu {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  flex: none;
  background: #fff;
}

.main-menu-list {
  display: flex;
}

.main-menu-item {
  flex: 1;
}

.main-menu-link {
  color: #00697E;

  text-align: center;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: -0.0241176em;

  display: block;
  padding: 37px 0 14px;

  opacity: .6;
  background: url('../images/icon-tabs-panel-home.png') no-repeat center 16px;

  @include unselectable;

  &:hover,
  &.active {
    opacity: 1;
  }
}

.main-menu-payments {
  background-image: url('../images/icon-tabs-panel-payments.png');
}

.main-menu-prices {
  background-image: url('../images/icon-tabs-panel-prices.png');
}

.main-menu-settings {
  background-image: url('../images/icon-tabs-panel-settings.png');
}

.main-menu-wallet {
  background-image: url('../images/icon-tabs-panel-wallet.png');
}


/**
 * Tabs
 */

.tabs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding-top: 20px;

  .tab:first-child {
    border-radius: 4px 0px 0px 4px;
    border-left: 1px solid #00E2D0;
  }

  .tab:last-child {
    border-radius: 0px 4px 4px 0px;
  }
}

.tab {
  color: #00E2D0;
  font-size: 10px;
  font-weight: 600;

  letter-spacing: -0.05em;
  text-align: center;

  padding: 10px 0;

  border: 1px solid #00E2D0;
  border-left: 0;

  flex-grow: 1;

  &:hover,
  &:focus,
  &.active {
    color: #00697E;
    background: #00E2D0;
  }
}

/**
 * Top Menu
 */

.top-menu {
  padding: 16px 0;
  background: #00697E;
}

.top-menu-list {
  display: flex;
  justify-content: space-evenly;


  > li {
    border-left: 1px solid rgba(255,255,255,.2);
    width: 100%;

    &:first-child {
      border: 0;
    }
  }
}

.top-menu-link {
  color: #fff;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  display: block;
  padding: 5px 8px 7px;
}
