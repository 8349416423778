/**
 * Date Input
 */

.input-date {
  color: #7EB7C4;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  display: inline-block;
  position: relative;

  width: 100%;
  padding: 3px 3px 3px 20px;

  border-radius: 4px;
  border: 1px solid #7EB7C4;

  background: transparent;

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 22px;
}

.label-date {
  position: relative;
  cursor: pointer;

  .input-date+.icon-date {
    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 2px;
      left: 3px;
      background: url("../images/icon-cal.png") no-repeat;
    }
  }

  /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  /* adjust increase/decrease button */
  input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
    display: none;
  }

  /* adjust clear button */
  input[type="date"]::-webkit-clear-button {
    z-index: 1;
  }
}
