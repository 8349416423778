/**
 * Sections
 */

.section {
  margin: 16px 0;

  .col {
    padding: 0 16px;
  }
}

.section-title {
  color: #2E363C;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;
}
