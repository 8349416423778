/**
 * Last Transaction
 */

.last-transactions {
  margin-top: -18px;
}

.last-transactions-list {
  margin: 0;
}

.lt-item {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 14px 16px;
}

.lt-item-status {
  color: #00697E;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
}

.lt-date {
  font-size: 12px;
  letter-spacing: -0.05em;

  color: #979797;
}

.lt-details-link {
  display: block;
  position: relative;

  &:before {
    content: '';
    position: absolute;

    width: 13px;
    height: 24px;

    right: 16px;
    top: 50%;

    margin-top: -12px;

    background: url('../images/btn-back-sm.png') no-repeat;
  }
}

.lt-details {
  background: #F4F5F5;
  border-radius: 4px;

  padding: 12px 15px 14px;
  margin: 9px 0;
}

.lt-details-val {
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: -0.05em;

  color: #FE7472;
}

.lt-details-val-success {
  color: #6AC05C;
}

.lt-details-fee {
  font-size: 12px;
  letter-spacing: -0.05em;

  color: #979797;
}

.lt-directions {
  font-size: 12px;
  letter-spacing: -0.05em;
}

.lt-label {
  color: #979797;
  display: block;
}

.lt-addr {
  color: #2E363C;
  display: block;
  word-break: break-all;
}

.lt-tags {
  padding: 14px 0 2px;
}

.lt-addr {
  margin-top: 3px;
}

.lt-directions-from {
  margin-top: 10px;
}

/**
 * Last Transaction (Alternate)
 */
.last-transactions-alt {
  .section-title {
    margin: 14px 0 10px;
  }

  .btn-details {
    margin-left: 16px;
  }

  .lt-details-val {
    color: #2E363C;
  }

  .lt-date {
    margin-top: 9px;
  }
}

.lt-content {
  flex: 100%;
  margin-top: 10px;
  display: none;

  &.active {
    display: block;
  }

  .lt-details-fee {
    margin-bottom: 10px;
  }
}