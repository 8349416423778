/**
 * Exchange Cryptocurrencies
 */
.exchange-cryptocurrencies {
  margin-bottom: 16px;
}

.ec-item-col {
  flex: 50%;
}

.ec-item-details {
  display: block;
  margin-left: 38px;
}

.ec-title {
  color: #2E363C;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: -0.05em;

  display: block;
  margin-top: -1px;
}

.ec-value {
  color: #979797;
  font-size: 12px;
  letter-spacing: -0.05em;
  display: block;
  margin-top: 3px;
}

.ec-logo {
  float: left;
}

.ec-footer {
  padding: 16px;
  background: #F4F5F5;
  display: flex;
}

.ec-total-block {
  width: auto;
  margin: -6px 30px 0 0;
}

/**
 * Exchange Cryptocurrencies ( Alternative )
 */
.exchange-cryptocurrencies-alt {
  .eca-item {
    padding: 18px 15px 18px 113px;
    background: #144A55 url('../images/icon-balance-bitcoin.png') no-repeat 15px center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &.eca-item-ethereum {
      background-image: url('../images/icon-balance-ethereum.png');
    }

    &.eca-item-zloadr {
      background-image: url('../images/icon-balance-zloadr.png');
    }

  }

  .eca-title,
  .eca-value {
    display: block;
    @include unselectable;
  }

  .eca-title {
    color: #00E2D0;
    font-size: 10px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .eca-value {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.05em;
    margin-bottom: 12px;
  }

  .ec-footer {
    padding: 33px 0;
    background: transparent;
    display: flex;
    align-items: flex-start;

    .ec-title {
      font-weight: 600;
      font-size: 10px;
      letter-spacing: -0.05em;

      color: #00E2D0;
    }

    .ec-value {
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.05em;
    }

    .btn.btn-inverse {
      color: #144A55;
    }
  }

}

/**
 * Responsive tweaks
 */
@media only screen and (max-width: 358px) {
  .exchange-cryptocurrencies-alt {
    .eca-value {
      font-size: 16px;
    }
  }
}