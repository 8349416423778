/**
 * Calculation Results
 */
.calculation-results {
  padding: 16px 0;
}

.cr-item + .cr-item {
  margin-top: 16px;
}

.cr-item {
  color: #2E363C;

  font-size: 20px;
  letter-spacing: -0.05em;
  text-align: right;

  b {
    font-weight: 600;
  }
}