/**
 * Typography
 */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #95999d;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #95999d;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #95999d;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #95999d;
}

// Disable text selection
@mixin unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// No Scroll
html {
  &.no-scroll {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
}

body {
  &.no-scroll {
    font-family: sans-serif;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

// Truncated text
.truncated {
  text-overflow: ellipsis;
  word-break: break-all;
}

body,
a,
p,
span,
h1,
h2,
h3,
input {
  font-family: $font-family;
}

body {
  height: 100%;
  min-height: $body-min-height;

  display: flex;
  flex-direction: column;

  background: #fcfcfd;
}

a {
  color: #55A0B2;
  text-decoration: none;

  &:hover {
    opacity: .8;
  }
}

b,
strong {
  font-weight: 600;
}

.text-attended {
  color: #00E2D0;
}

.text-center {
  text-align: center;
  display: block;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-normal {
  font-weight: 400;
  text-transform: none;
}

.text-strong {
  font-weight: 600;
}

.text-warning {
  color: #FF2D55;
}

.text-success {
  color: #00E2D0;
}

.no-scroll {
  overflow: hidden;
}

.learn-more {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.05em;
  color: #979797;

  p + p {
    margin-top: 16px;
  }
}

.paper {
  background: #fcfcfd;
}

.rt {
  text-align: right;
}

.placeholder {
  color: #979797;
}

*:focus {
  outline: none;
}
