/**
 * Social Stats
 */
.social-stats-panel {
  display: flex;
  justify-content: center;
  padding: 8px 0 0;
}

.social-stats-item {
  width: calc(100% / 4);

  span {
    display: block;
    text-align: center;
  }
}

.social-stats-icon {
  margin-bottom: 8px;
}

.social-stats-val {
  color: #00697E;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.05em;
}
