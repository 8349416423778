/**
 * Styles for technical pages (index.html, design-system.html)
 * and development stage
 */
.dev-stage {
  padding: 0 0 100px;

  .main {
    .grid {
      padding: 0 15px;
    }
  }

  h1 {
    font-size: 35px;
    padding: 30px 0;
  }

  h2 {
    font-size: 25px;
    padding: 25px 0;
  }

  h3 {
    font-size: 20px;
    padding: 25px 0;
  }

  .pages-item {
    display: block;
    padding: 5px 0;

    a {
      display: block;
      padding: 5px 0;
    }
  }

  .divider {
    display: block;
    border-bottom: 2px dotted #000;
    height: 10px;
  }

  li span {
    display: block;
    padding: 10px 0;
  }

  code {
    font-size: 12px;
  }

  iframe {
    max-width: 417px;
    min-height: 667px;
    width: 100%;
  }

  .preview-list {
    li+li {
      margin-top: 15px;
    }

    p {
      font-size: 12px;
      padding: 5px 0;
    }
  }
}