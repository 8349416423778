/**
 * Price Line
 */
.price-line {
  background: #55A0B2;

  display: flex;
  justify-content: center;

  padding: 16px 0;

  > li {
    color: #fff;
    font-size: 12px;
    letter-spacing: -0.05em;
    text-transform: uppercase;

    border-left: 1px solid #73b1bf;

    padding: 0 10px;

    span {
      color: #00697E;
    }

    &:first-child {
      border: 0;
    }
  }
}
