/**
 * Roadmap
 */

.roadmap {
  display: flex;
  flex-direction: column;

  >.roadmap-item:last-child {
    &:after {
      background: transparent;
    }
  }
}

.roadmap-item {
  position: relative;
  padding: 0 0 36px 40px;

  &:before {
    content: '';
    position: absolute;

    left: 0;
    top: 0;

    width: 24px;
    height: 24px;

    background: #fff;
    border: 2px solid #00E2D0;
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;

    left: 13px;
    top: 26px;

    width: 2px;
    height: 100%;

    background: #00E2D0;

  }

  span {
    display: block;
  }

  &.active {
    &:before {
      border-color: #2E363C;
    }

    &:after {
      background-color: #2E363C;
    }

    .roadmap-date {
      color: #2E363C;
    }

    .roadmap-title:before {
      background-color: #2E363C;
    }
  }
}

.roadmap-date {
  color: #00E2D0;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;
  padding-bottom: 7px;
}

.roadmap-title {
  color: #2E363C;
  font-size: 16px;
  letter-spacing: -0.05em;

  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 16px;
    height: 2px;
    background: #00E2D0;
  }
}