/**
 * Chart: Exchange Rates Comparison
 * 15 Exchange Due Diligence Report
 */

.exchange-rates-comparison {
  background: #00697E;
  padding: 16px;

  .erc-title {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    padding: 8px 0 32px;
  }
}

.erc-content {
  padding: 16px 0;
}

.erc-list {
  >li:first-child {
    margin-top: 0;
  }
}

.erc-item {
  margin-top: 16px;
}

.erc-legend {
  display: flex;
}

.erc-legend-label {
  display: block;
  position: relative;

  width: 32px;
  height: 32px;

  background: #E87162;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;

  margin-right: 8px;
}

.erc-legend-label-1 {
  background: #E87162;
}

.erc-legend-label-2 {
  background: #55A0B2;
}

.erc-legend-label-3 {
  background: #FF4274;
}

.erc-legend-label-4 {
  background: #00E2D0;
}

.erc-legend-label-5 {
  background: #5F5E5D;
}

.erc-legend-label-6 {
  background: #B4B2AE;
}

.erc-legend-label-7 {
  background: #FE7472;
}

.erc-legend-label-8 {
  background: #199ED8;
}

.erc-legend-label-9 {
  background: #ABDFAB;
}

.erc-legend-label-10 {
  background: #96D900;
}

.erc-legend-title {
  color: #fff;
  font-size: 16px;
  line-height: normal;
  letter-spacing: -0.05em;

  display: block;
}

.erc-legend-value {
  color: #C4C4C4;
  font-size: 12px;
  letter-spacing: -0.05em;

  display: block;
  margin-top: 2px;
}