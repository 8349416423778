/**
 * Popup Modal
 */

/* The Modal (background) */
.popup-modal {
  /* Hidden by default */
  display: none;

  /* Stay in place */
  position: fixed;

  /* Sit on top */
  z-index: 1;

  /* Location of the box */
  padding: 40px;
  left: 0;
  top: 0;

  /* Full width / height */
  width: 100%;
  height: 100%;

  /* Enable scroll if needed */
  overflow: auto;

  /* Fallback color */
  background-color: rgb(0, 0, 0);

  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);

  &.active {
    display: flex;
  }


  /* Modal Content */
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;

    margin: auto;

    width: 100%;
    max-height: 100%;

    border-radius: 4px;

    background: #fff;

    animation-name: animationFade;
    animation-duration: 0.4s;

    .modal-subtitle {
      color: #00697E;
      font-weight: 600;
      line-height: 22px;
      font-size: 16px;
      letter-spacing: -0.05em;
      padding: 10px 0 0;
    }

    p {
      color: #2E363C;
      line-height: 18px;
      font-size: 14px;
      letter-spacing: -0.05em;
      padding: 10px 0;
    }
  }

  /* Add Animation */

  @keyframes animationFade {
    from {
      top: 0;
      opacity: 0
    }

    to {
      top: 0;
      opacity: 1
    }
  }

  /* The Close Button */
  .close {
    color: #fff;
    cursor: pointer;

    width: 10px;
    height: 10px;

    position: absolute;
    right: 16px;
    top: 28px;

    @include unselectable;

    background: url('../images/btn-close.png') no-repeat;
  }

  .modal-header {
    position: relative;
  }

  .modal-title {
    color: #000;
    font-weight: 600;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.05em;
    padding: 16px 16px 10px;
  }

  .modal-body {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
  }
}

.popup-filter-options {
  z-index: 10;
}