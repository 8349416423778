/**
 * Sliders
 */

.tns-nav {
  text-align: center;
  margin: 5px 0 0;
}

.tns-nav>[aria-controls] {
  width: 8px;
  height: 8px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #979797;
  border: 0;
  opacity: 0.3;
}

.tns-nav>.tns-nav-active {
  opacity: 1;
}

.item-card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-height: 222px;
  margin: 2px 8px;
}

.item-card * {
  @include unselectable;
}

.tns-horizontal.tns-subpixel { white-space: normal !important; }
