/**
 * Exchange Features
 */

.exchange-features {
  padding: 10px 0;

  p {
    color: #2E363C;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.05em;
    font-feature-settings: 'pnum' on, 'onum' on;

    max-width: 240px;
    margin: 0 auto;

  }

  .section-title {
    margin: 15px 0;
  }
}

.ef-item + .ef-item {
  margin-top: 35px;
}
