/**
 * Sell Cryptocurrencies
 */

.header-inputs {
  .grid {
    padding: 13px 0 33px;
  }
}

.sc-header {
  color: #fff;
  background: #144A55;
  border-radius: 4px 4px 0 0;
}

.sc-content {
  background: #fff;
  border-radius: 0 0 4px 4px;
  position: relative;
}

.sc-content-details {
  color: #7EB7C4;
  font-size: 16px;
  letter-spacing: -0.05em;

  position: absolute;
  right: 16px;
  top: 24px;
}

.sc-input {
  color: #2E363C;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;

  border-radius: 0 0 4px 4px;

  border: 0;
  width: 100%;

  padding: 22px 16px;
}

.sc-title {
  color: #fff;
  font-size: 20px;
  letter-spacing: -0.05em;
  font-weight: 600;

  display: block;
  padding: 12px 15px;
  position: relative;
}

.btn-select-popup {
  color: #fff;
  font-size: 20px;
  letter-spacing: -0.05em;

  display: block;
  padding: 12px 15px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    right: 16px;
    bottom: 18px;
    background: url('../images/btn-shrink.png') no-repeat;
  }

  .select-type {
    color: #fff;
  }

  .select-icon {
    width: 16px;
    height: 16px;
    margin: 5px 10px 0 0;
    top: 0;
  }
}

.sc-footer {
  margin-top: 40px;
}

.icon-ex {
  background: url('../images/icon-ex.png') no-repeat center center;
  height: 50px;
  width: 100%;
}
