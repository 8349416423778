/**
 * Alert Panels
 * Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages.
 */

.alert {
  border-radius: 4px;

  span {
    color: #fff;
    display: block;
  }

  margin: 24px;
  padding: 15px;
}


.alert-info {
  background: #00E2D0 url('../images/icon-lg-percents.png') no-repeat 16px 16px;
  padding: 16px 16px 16px 70px;
  margin: 0 16px;

  p {
    color: #00697E;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.05em;
  }
}

.alert-danger {
  background: #FE7472;
}

.alert-success {
  background: #6AC05C;
}

.alert-title {
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
}

.alert-value {
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: -0.05em;
  opacity: 0.9;
}