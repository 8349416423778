/**
 * Cards Tablets
 */

.cards-tablets {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 32px;

  .ct-item {
    width: 50%;
    padding: 8px;
  }

  .ct-item-wide {
    width: 100%;

    .card {
      display: flex;
      .ct-content {
        margin: 0 16px 0 0;
        width: 100%;
      }
    }
  }

  .card {
    padding: 16px 0;
    width: 100%;
    @include clearfix;
  }

  &.nomargin {
    margin-bottom: 0;
  }
}

.ct-title {
  color: #979797;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.ct-info {
  color: #2E363C;
  font-size: 16px;
  letter-spacing: -0.05em;
}

.ct-icon {
  float: left;
  width: 50px;
  text-align: center;
  margin-top: 3px;
}

.ct-content {
  margin-left: 16px;

  .progressbar {
    margin: 0;
  }
}


