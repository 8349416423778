/**
 * Contacts Links
 */
.contacts-links {
  display: flex;
  flex-wrap: wrap;

  .contact-link {
    color: #A4A4A4;
    font-size: 12px;
    text-align: center;
    letter-spacing: -0.05em;

    width: calc(100% / 3);

    span {
      display: block;
    }

    .contact-icon {
      height: 67px;
    }

    .card {
      height: 113px;
      margin: 4px;

      flex-direction: column;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.card-expanded {
  .card {
    box-shadow: none;
  }
}