/**
 * Buttons
 */

.btn {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 50px;

  border: 1px solid transparent;
  border-radius: 4px;

  display: block;
  width: 100%;

  background: #00697E;

  @include unselectable;

  &.btn-disabled {
    opacity: .5;
  }

  &.btn-inverse {
    color: #fff;
    background: #00E2D0;
  }

  &.btn-outline {
    color:#00697E;
    background: #fff;
    border: 1px solid #7EB7C4;

    &.text-normal {
      font-weight: 400;
      text-transform: none;
    }

    &.btn-inverse {
      color: #00E2D0;
      border-color: #00E2D0;
      background: transparent;
    }

    &.btn-warning {
      color: #FE7472;
      border-color: #FE7472;
    }

    &.btn-success {
      color: #00E2D0;
      border-color: #00E2D0;
    }
  }

  &.text-uppercase {
    text-transform: uppercase;
  }
}

.btn-short-line {
  text-indent: -9999px;
  width: 16px;
  height: 2px;
  display: block;
  margin: 9px auto 0;
  background: #00E2D0;
}

.btn-checkbox {
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #7EB7C4;
  text-decoration: none;

  line-height: 22px;

  border: 1px solid #7EB7C4;
  box-sizing: border-box;
  border-radius: 4px;

  background: #fff;

  width: 46px;
}

.btn-checkbox.active,
.btn-checkbox:hover {
  color: #fff;
  background: #00697E;
}

.btn-card-resize {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: url('../images/btn-shrink.png') no-repeat;
}

.btn-details {
  display: inline-block;
  width: 8px;
  height: 13px;

  background: url('../images/icon-arrow-right.png') no-repeat;
}

.btn-inline {
  display: inline-block;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
}


/**
 * Small Buttons
 */
.btn-sm {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  text-transform: none;
}

.header {
  .btn-sm {
    &.btn-inverse {
      color: #144A55;

      &.btn-outline {
        color: #00E2D0;
        background: transparent;
      }
    }
  }
}

/**
 * Filter Buttons
 */
.btn-filter {
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  padding: 16px 0;
  width: 50%;
}

.btn-filter-apply {
  color: #144A55;
  background: #00E2D0;
}

.btn-filter-reset {
  color: #00E2D0;
  background: #144A55;
}

.btn-tag {
  color: #7EB7C4;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  white-space: nowrap;

  display: inline-block;
  position: relative;

  margin: 0 5px 5px 0;
  padding: 5px 26px 5px 8px;

  border: 1px solid #7EB7C4;
  border-radius: 4px;

  @include unselectable;

  &:before {
    content: '';

    position: absolute;

    width: 16px;
    height: 16px;

    top: 2px;
    right: 3px;

    background: url('../images/icon-clear.png') no-repeat;
  }
}

.btn-add {
  background: url('../images/icon-add.png') no-repeat;
  display: inline-block;
  position: relative;
  width: 23px;
  height: 23px;
  margin: 0 5px 0 8px;
  top: 7px;
}

.btn-tag-select {
  color: #7EB7C4;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  white-space: nowrap;

  display: inline-block;
  position: relative;

  width: 100%;

  padding: 5px 8px;

  border: 0;
  outline: 0;

  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include unselectable;

  &.active {
    color: #00697E;
    background: #7EB7C4;

    &:before {
      background-image: url('../images/icon-expand-active.png');
    }
  }
}

.btn-tag-select-wrapper {
  display: block;
  position: relative;
  border: 1px solid #7EB7C4;
  border-radius: 4px;

  margin: 0 5px 5px 0;

  &:before {
    content: '';

    position: absolute;

    width: 4px;
    height: 4px;

    bottom: 3px;
    right: 3px;

    background: url('../images/icon-expand.png') no-repeat;
  }

}

/**
 * WM Buttons
 */

.wm-btn-add {
  text-indent: -9999px;

  display: block;
  position: absolute;

  width: 24px;
  height: 24px;

  top: 16px;
  right: 16px;
  background: url('../images/icon-wm-add.png') no-repeat;
}

.wm-btn-copy {
  text-indent: -9999px;

  display: inline-block;
  width: 24px;
  height: 24px;

  background: url('../images/icon-wm-copy.png') no-repeat;
}

.wm-btn-edit {
  text-indent: -9999px;

  display: inline-block;
  width: 26px;
  height: 25px;

  background: url('../images/icon-wm-edit.png') no-repeat;
}
