/**
 * Main
 */
.main {
  flex: 1 0 auto;
}

.main .grid {
  display: block;
}

.main-title,
.main-title-l {
  color: #00697E;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;
  text-align: center;

  padding: 12px 0 19px;
}

.main-title-l {
  text-align: left;
}

.main-title-alt {
  color: #2E363C;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;
  text-align: center;

  padding: 12px 0 19px;
}

.main-message {
  color: #2E363C;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: -0.05em;

  margin: 0 0 30px;
}

/**
 * Confirmation Section
 */
.confirmation {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  padding: 18px 32px;
  margin: 0 0 30px;
  background: #fff;
}

.confirmation-title {
  color: #979797;
  font-size: 10px;
  letter-spacing: -0.05em;
  text-align: center;
  text-transform: uppercase;
  display: block;
}

.confirmation-data {
  color: #2E363C;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.05em;
  text-align: center;

  background: #F4F5F5;
  border-radius: 4px;

  margin: 9px 0 11px;
  padding: 7px 0 10px;

  @include unselectable;
}

.confirmation-link {
  color: #55A0B2;
  font-size: 16px;
  letter-spacing: -0.05em;
  text-align: center;
  display: block;
}