/**
 * Panels
 */
.panel {
  margin: 16px 0;
}

.panel-default {
  background: #F4F5F5;
  border-radius: 4px;
  padding: 16px;

  .input-field-focus input {
    background: transparent;
  }
}
