/**
 * FAQ Section
 */

.faq {
  padding: 16px;

  p {
    line-height: 19px;
  }

  p+p {
    margin-top: 8px;
  }
}

.faq-title {
  color: #7EB7C4;

  font-weight: 600;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  padding: 16px 0;
}

.faq-item {
  padding: 16px 0;
  border-top: 1px solid #F4F5F5;
}

.faq-question,
.faq-answer {
  padding: 0 0 0 40px;
  position: relative;

  &:before {
    content: 'Q';

    color: #00697E;

    font-size: 16px;
    text-align: center;
    letter-spacing: -0.05em;
    line-height: 32px;

    position: absolute;
    top: 0;
    left: 0;

    width: 32px;
    height: 32px;

    border: 1px solid #00697E;
    border-radius: 50%;

    opacity: .3;
  }
}

.faq-question {
  padding-top: 8px;
  padding-bottom: 24px;

  p {
    font-size: 16px;
    letter-spacing: -0.05em;
    color: #144A55;
    font-weight: bold;
  }
}

.faq-answer {

  &:before {
    content: 'A';
    color: #2E363C;
    border-color: #2E363C;
  }

  p {
    color: #2E363C;
    font-size: 16px;
    letter-spacing: -0.05em;
  }
}