/**
 * Cards Tablets
 */

.card-expanded {
  h3 {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.05em;
    color: #2E363C;
    padding: 16px;
  }

  .toggle-content {
    padding: 0 0 40px;

    > p {
      padding: 0 16px;
    }
  }
}
