/**
 * Sellers
 */
.sellers {
  a {
    width: 33.3%;

    img {
      width: 100%;
    }
  }
}
