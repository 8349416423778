/**
 * Due Diligence Score
 * 14 Token Sale
 */

.due-diligence-score {
  padding: 25px;
}

.sphere {
  width: 240px;
  height: 240px;
  background: linear-gradient(180deg, #00E2D0 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(141.61deg, #00697E 12.87%, #00E2D0 86.66%);
  border-radius: 50%;
  display: flex;
  margin: 0 auto;

  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sphere-title {
  color: #fff;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  padding: 0 0 14px;
}

.sphere-subtitle {
  color: #144A55;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  opacity: 0.7;
}

.sphere-score {
  color: #fff;
  font-weight: 800;
  font-size: 64px;
  letter-spacing: -0.05em;

  padding: 4px 0;
}

.sphere-count {
  color: #144A55;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;

  padding: 0 0 10px;

  opacity: 0.7;
}

.sphere-stars {

}
