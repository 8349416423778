/**
 * Checkbox
 */

.checkbox-item {
  margin-top: 13px 0 8px;
}

/* The container */
.checkbox-field {
  font-size: 22px;

  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  cursor: pointer;
  @include unselectable;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: 2px solid #55A0B2;
    background-color: #fff;
  }

  /* On mouse-over, add a grey background color */
  &:hover input~.checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked~.checkmark {
    background-color: #55A0B2;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 50%;
    top: 50%;

    margin: -4px 0 0 -6px;

    width: 13px;
    height: 9px;
    background: url('../images/icon-checkbox.png') no-repeat 0 0;
  }

  .checkbox-label {
    color: #2E363C;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: -0.05em;
    top: -2px;
    display: block;
    position: relative;
  }
}

/**
 * Checkbox Tag
 */
.checkbox-tag {
  color: #7EB7C4;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  display: inline-block;
  margin: 0 5px 5px 0;

  cursor: pointer;

  @include unselectable;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked~.checkmark {
      color: #00697E;
      background: #7EB7C4;
    }
  }

  .checkmark {
    white-space: nowrap;

    display: block;
    position: relative;
    padding: 5px 8px;

    border: 1px solid #7EB7C4;
    border-radius: 4px;
  }

}

.radio-field {

  margin: 0;

  .checkbox-label {
    padding-top: 4px;
    display: block;
  }

  .checkmark {
    border-radius: 50%;
  }
}

.checkbox-date-tag {
  width: 100%;
  position: relative;

  input {
    opacity: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
  }

  input::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .checkmark {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: '';

      position: absolute;

      width: 16px;
      height: 16px;

      top: 2px;
      left: 3px;

      background: url('../images/icon-cal.png') no-repeat;
    }
  }

}
