/**
 * Section Settings
 */

.settings {
  padding: 16px 0;

  .main-title {
    color: #00697E;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: -0.05em;
    text-align: left;
    padding: 24px 0 0;
  }

  .pl-link {
    padding: 16px 0;
  }

  .settings-logout-panel {
    padding: 16px 0 32px;
  }

  .btn-logout {
    color: #00697E;

    font-size: 16px;
    text-align: center;
    letter-spacing: -0.05em;

    display: block;

    border: 1px solid #7EB7C4;
    border-radius: 4px;
    padding: 16px;
  }
}
