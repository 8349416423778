/**
 * Form Elements
 */

@mixin float-label {

  label,
  >span {
    color: #7EB7C4;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: -0.05em;

    position: absolute;
    left: 0;
    bottom: 24px;
    cursor: text;
    opacity: 1;
    transition: all .2s;
    @content;
    @include unselectable;
  }
}

@mixin float-label-input {
  &::placeholder {
    opacity: 1;
    transition: all .2s;
  }

  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus)+* {
    color: #2E363C;
    font-size: 20px;
    opacity: .5;
    @content;
  }
}

.form-title {
  color: #000;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.05em;

  padding: 2px 0 6px;
}

.input-field {
  padding: 0;

  display: block;
  position: relative;

  @include float-label;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  textarea {
    width: 100%;
  }

  input,
  select,
  textarea {
    @include float-label-input;

    color: #2E363C;
    line-height: 22px;
    font-size: 20px;
    letter-spacing: -0.05em;

    padding: 0 0 3px;
    margin: 0;

    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #C4C4C4;

    width: 100%;

    background: transparent;

    @include float-label-scaled {
      bottom: 3px;
    }

    &:focus {
      outline: none;
      border-color: #00697E;
    }

  }

  select {
    padding-right: 10px;
    background: url('../images/icon-select.png') no-repeat 100% 15px;
  }
}


/**
 * Focused input
 */
.input-field-focus {
  position: relative;

  input {
    color: #2E363C;
    line-height: 22px;
    font-size: 20px;
    letter-spacing: -0.05em;

    padding: 0 0 3px;
    margin: 0;

    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #C4C4C4;

    width: 100%;

    &::placeholder {
      color: #2E363C;
    }

    @include float-label-scaled {
      bottom: 3px;
    }

    &:focus {
      outline: none;
      border-color: #00697E;
    }

    padding-right: 30px;
  }


  .label {
    color: #7EB7C4;
    font-size: 10px;
    line-height: 22px;
    letter-spacing: -0.05em;
    top: 0;
    cursor: text;

    @include unselectable;
  }

  .btn-qr {
    position: absolute;
    bottom: 8px;
    right: 0;
    width: 24px;
    height: 25px;
    background: url('../images/icon-qr.png') no-repeat;
  }
}

.validation-message {
  color: #FF2D55;
  font-size: 10px;
  letter-spacing: -0.05em;

  display: none;

  position: absolute;
  bottom: -17px;
}

/**
 * Input Item
 */
.input-item {
  padding: 27px 0;
}

/**
 * Labels
 */
.label-forgot-pass {
  color: #55A0B2;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;

  padding: 5px 0;
  margin: 17px 0 0;
}

.signup-promotion {
  color: #2E363C;
  line-height: 30px;
  font-size: 20px;
  letter-spacing: -0.05em;

  padding: 50px 0 35px;
}

.login-btn-panel {
  padding: 14px 0 0;
}

/**
 * Validation Error
 */
.validation-error {
  .input-text {
    border-color: #FF2D55;
  }

  .validation-message {
    display: block;
  }
}

/**
 * Input-Select
 */
.input-select {
  cursor: pointer;

  color: #2E363C;

  line-height: 25px;
  font-size: 20px;
  letter-spacing: -0.05em;

  padding-right: 10px;

  display: block;
  width: 100%;

  padding: 0 0 2px;
  margin-top: -2px;

  border-bottom: 1px solid #C4C4C4;

  @include unselectable;

  &:after {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 9px;
    background: url('../images/icon-select.png') no-repeat;
    width: 4px;
    height: 4px;
  }

  span {
    color: #7EB7C4;
  }
}

/**
 * Toggle input
 */
.form-item {
  margin-bottom: 20px;

  .btn-accordion {
    color: #2E363C;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.05em;

    display: block;
    position: relative;

    background: #F4F5F5;
    padding: 16px;

    @include unselectable;

    &:before {
      content: '';
      position: absolute;

      width: 8px;
      height: 8px;

      top: 50%;
      right: 16px;

      margin-top: -4px;

      background: url('../images/btn-shrink.png') no-repeat;
    }
  }

  &.active {
    .btn-accordion {
      background: #fff;

      &:before {
        background-image: url('../images/btn-shrink-active.png');
      }
    }
  }

  .toggle-content {
    margin: 0 16px 30px;
  }

}

.categories-fieldset {
  margin-bottom: 16px;
  margin-top: -8px;
}
