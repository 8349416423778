/**
 * Toggle
 */
.toggle-content {
  display: none;
}

.toggle-area {

  &.active {
    .toggle-content {
      display: block;
    }

    .btn-card-toggle {
      background-image: url('../images/btn-shrink-active.png');
    }
  }
}

.toggle-footer {
  position: relative;
}

.btn-card-toggle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  background: url('../images/btn-shrink.png') no-repeat 8px 8px;
}
