/**
 * Card Top
 */
.card-top {

  span {
    display: block;
  }

  img {
    margin-right: 16px;
  }
}

.card-top-header {
  color: #fff;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;

  padding: 5px 0 8px;
}

.card-top-subheader {
  color: #7EB7C4;
  font-size: 16px;
  letter-spacing: -0.05em;

  position: relative;

  &:before {
    content: '';
    position: absolute;

    width: 16px;
    height: 2px;
    left: 0;
    bottom: -16px;

    background: #00E2D0;
  }
}
