.order-status {
  margin: 16px 0 8px;
  width: 100%;
  overflow: hidden;
}

.order-status-list {
  display: flex;
  justify-content: space-around;
  text-align: center;

  >li:last-child {
    .order-status-shape {
      &:before {
        background: transparent;
      }
    }
  }

}

.order-status-item {

  &.active {
    .order-status-shape {
      background: #00E2D0;
      box-shadow: none;
    }
  }
}

.order-status-shape {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 80px;
  height: 80px;

  margin: 0 0 5px;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background: #fff;

  &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 50px;
    left: 100%;
    top: 50%;
    margin-top: -1px;

    background: #00E2D0;
  }
}

.order-status-label {
  color: #00E2D0;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.05em;
}


/**
 * Responsive tweaks
 */
@media only screen and (max-width: 358px) {
  .order-status-shape {
    width: 70px;
    height: 70px;
  }
}
