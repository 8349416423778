/**
 * Search Panel
 */

.search-panel {
  padding-top: 16px;
}

.search-form {
  background: #144A55;
  border: 1px solid #00697E;
  border-radius: 4px;
  height: 32px;
  position: relative;

  .btn-clear {
    position: absolute;
    top: 0;
    right: 0;

    width: 32px;
    height: 32px;

    background: url('../images/btn-clear.png') no-repeat center center;

    display: none;
  }

  &.active {
    .btn-clear {
      display: block;
    }
  }
}

.search-form-input {
  color: #fff;
  font-family: $font-family;
  font-size: 16px;
  letter-spacing: -0.05em;

  margin: 5px 0 0;
  padding: 0 10px 0 30px;

  width: 100%;
  border: 0;
  background: transparent url('../images/icon-search.png') no-repeat 6px 2px;


  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #7EB7C4;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #7EB7C4;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #7EB7C4;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #7EB7C4;
  }
}