/**
 * Cards
 */
.card-deck {
  margin: 19px 0 0;

  .card {
    margin-bottom: 8px;
  }
}

.cards {
  .card {
    margin-bottom: 8px;
  }

  .learn-more {
    margin-top: 17px;
  }
}

.card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #fff;

  .toggle-content {
    padding-bottom: 24px;
  }
}

.card-body {
  padding: 16px;
}

.card-header {
  padding: 16px;
}

.card-header-img {
  float: left;

  span {
    color: #2E363C;
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.05em;

    display: block;

    padding: 8px 0 0;
    clear: both;
  }
}

.card-header-details {
  display: block;
  margin-left: 16px;

  p {
    white-space: nowrap;
  }

  .card-header-subtitle {
    margin-top: 10px;

    p {
      white-space: normal;
    }
  }

}

.card-header-hint {
  font-size: 12px;
  letter-spacing: -0.05em;
  color: #979797;
  margin-top: 13px;
}

.card-header-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: #2E363C;
  margin-top: 9px;

  white-space: nowrap;

  width: 50px;
}

.val-usd {
  font-size: 16px;
  letter-spacing: -0.05em;
  color: #144A55;
  margin-top: 12px;
}

.val-etc {
  font-size: 12px;
  letter-spacing: -0.05em;
  color: #979797;
  margin-top: 4px;
}

.val-btc {
  font-size: 12px;
  letter-spacing: -0.05em;
  color: #979797;
  margin-top: 3px;
}

.card-marked {
  background: #F1F7F8;
  padding: 8px 16px;

  strong {
    white-space: nowrap;
  }
}

.card-marked p {
  color: #00697E;
  font-size: 12px;
  letter-spacing: -0.05em;
}

.card-marked p strong {
  font-weight: 600;
}

.due-diligence-title {
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.05em;
  margin-top: 2px;

  color: #979797;
}

.due-diligence-val {
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.05em;

  color: #fff;

  background: #7EB7C4;
  border-radius: 4px;

  display: block;

  width: 80px;
  padding: 6px 0;
  margin: 6px auto 0;
}

.card-supply {
  padding: 16px 16px 0;
}

.card-supply-title {
  color: #979797;
  font-size: 10px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  white-space: nowrap;
  width: 0;
}

.card-supply-info {
  font-size: 12px;
  letter-spacing: -0.05em;
  color: #2E363C;
  margin-top: 6px;
  text-transform: uppercase;
}

.card-action {
  margin-top: 18px;
  padding: 0 16px 24px;
}

.card-item {
  padding: 12px 0 16px;
  margin: 0 16px;
  display: flex;

  .col-6 {
    margin: 0;
  }
}

.card-item+.card-item {
  border-top: 1px solid#F4F5F5;
  padding: 16px 0;
}

.card-footer {
  background: #F4F5F5;
  border-radius: 0px 0px 4px 4px;
  padding: 8px 16px;
}

.card-footer-success {
  color: #00697E;
  font-size: 12px;
  letter-spacing: -0.05em;

  background: #00E2D0;

  span {
    color: #fff;
    font-weight: 600;
  }
}

.card-footer-block {
  margin-top: 16px;

  .card-footer-list {
    display: flex;
    justify-content: center;
  }
}

/**
 * Wallet Details
 */
.card-wallet-details {
  margin-bottom: 15px;

  @include clearfix;

  img {
    float: left;
  }

  h3 {
    font-weight: 600;
    line-height: normal;
    font-size: 16px;
    letter-spacing: -0.05em;
    color: #2E363C;
    padding: 0 8px 10px;
  }

  h3,
  .row {
    margin-left: 70px;
  }

}

.card-wallet-address {
  &.cw-border {
    padding: 16px 8px;
    margin: 24px 0 0;
    border-top: 1px solid #F4F5F5;
    border-bottom: 1px solid #F4F5F5;
  }
}

/**
 * Card Remaining
 */
.card-remaining {
  padding: 0 16px;

  .progressbar {
    margin-top: 3px;
  }
}

.card-remaining-value {
  text-transform: lowercase;
}

.card {
  .card-toggle-content {
    padding-bottom: 0;
  }
}

/**
 * Card with Chart
 */
.card-chart-header {
  color: #2E363C;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  display: block;
  margin: 0 0 8px 0;
}

.card-chart-subheader {
  color: #979797;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
}

.card-chart-title {
  color: #2E363C;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  letter-spacing: -0.05em;

  display: block;
  margin: 0 8px 8px 0;
}

.card-chart-value {
  color: #2E363C;
  font-size: 12px;
  text-align: right;
  letter-spacing: -0.05em;

  margin-right: 8px;
}

/**
 * Card Page Link
 */
.card-page-link {
  padding: 16px;
  display: flex;
  align-items: center;
  background: #00697E;
  box-shadow: none;
}

.cpl-image {
  margin-right: 16px;
}

.cpl-title {
  color: #00E2D0;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.05em;
}
