/**
 * Star Rating
 */
.stars-rating-list {
  text-align: center;
}

.stars-rating-item {
  display: inline-block;
}

.star {
  display: block;
  width: 9px;
  height: 9px;
  background: url('../images/icon-star.png') no-repeat;
}

.star.star-empty {
  background: url('../images/icon-star-empty.png') no-repeat;
}

/**
 * Alternative Stars Rating
 */
.stars-rating-alt {
  .star {
    background-image: url('../images/icon-star-alt.png');
  }

  .star.star-empty {
    background-image: url('../images/icon-star-alt-empty.png');
  }
}