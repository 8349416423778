/**
 * Credit Cards
 */

.credit-cards {
  h3 {
    color: #2E363C;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.05em;
    padding: 16px 0 8px;
  }

  .btn-outline {
    width: 147px;
  }
}

.credit-cards-list {
  padding: 32px 0 16px;

  >li:first-child {
    border-top: 1px solid #F4F5F5;
  }

}

.credit-card-item {
  padding: 16px;
  border-bottom: 1px solid #F4F5F5;

  display: flex;

  align-items: center;
}

.btn-clear-item {
  margin-left: auto;
  display: block;
  text-indent: -9999px;
  background: url('../images/btn-clear.png') no-repeat center center;
  width: 50px;
  height: 100%;
}

