/**
 * Pin
 */

$pk-btn-height: 47px;

.pin {
  margin-top: 20px;
}

.dots {
  width: 202px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}

.dot {
  position: relative;
  width: 32px;
  height: 32px;
  border: 2px solid #D9D9D9;
  border-radius: 50%;

  &.active {
    background: #00E2D0;
    border: 2px solid #00E2D0;
  }
}

/**
 * Pin Keyboard
 */
.pin-keyboard {
  background: rgba(10, 10, 10, 0.96);
}

.pk-btn {
  color: #fff;

  display: block;
  height: 100%;

  box-shadow: 0px 1px 0px #000;
  border-radius: 5px;
  background: #444;

  @include unselectable;

  &:hover {
    background: #777;
  }

  &.pk-btn-clear {
    background: transparent;
    box-shadow: none;
  }

  &.pk-btn-delete {
    background: url('../images/btn-delete.png') no-repeat center center;
    text-indent: -9999px;
  }
}

.pk-value {
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  display: block;
}

.pk-value-center {
  line-height: $pk-btn-height;
}

.pk-keys {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.2em;
  text-align: center;
  display: block;
}

.pk-list {
  display: flex;
  flex-wrap: wrap;

  padding: 5px;

  flex-direction: row;
}

.pk-item {
  flex: 1 1 25%;
  height: $pk-btn-height;
  margin: 5px;
}
