.footer {
  .footer-info {
    p {
      color: #2E363C;
      letter-spacing: -0.05em;
      font-size: 16px;
      text-align: center;
    }

    a {
      color: #00E2D0;
    }
  }
}
