/**
 * Header
 */

.header,
.footer {
  flex: none;
}

.header .grid {
  background: $header-gradient;
}

.header-splash .grid {
  width: 100%;
  height: 100vh;
}

.title {
  color: #fff;
  line-height: 22px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.0241176em;

  display: block;
}

// Fixed Header
.main-header {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.main-header .grid {
  background: $header-main-gradient;
  position: relative;
  height: 55px;
}

.main-header-alt .grid {
  height: 65px;
}

.page-wrapper {
  margin: 55px 0 0;
  -webkit-overflow-scrolling: touch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background: #fcfcfd;
}

.page-wrapper-alt {
  padding: 0;
}

// Header Extended
.header-extended {
  .grid {
    background: linear-gradient(180deg, #00697E -2.65%, #144A55 100%);
  }

  .col {
    padding-top: 0;
  }
}

.header-extended-footer {
  color: #7EB7C4;
  font-size: 12px;
  letter-spacing: -0.05em;

  padding: 16px;

  img {
    margin-right: 8px;
  }

  span {
    display: inline-block;
    position: relative;
    top: -3px;
  }
}


/**
 * Subtitle
 */
.subtitle {
  color: #F4F5F5;
  line-height: 20px;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  padding: 28px 40px 0;
}

.page-last-updates {
  color: #00E2D0;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.05em;
  padding: 13px 0 3px;
}

.page-description {
  color: #7EB7C4;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.05em;
  padding: 18px 0 0;
}

.subtitle-panel-logo {
  text-align: center;
  padding: 16px 0 10px;
}

.page-total {
  span {
    display: block;
  }
}

.page-total-title {
  color: #00E2D0;
  font-weight: 600;
  line-height: 20px;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  margin-bottom: 4px;
}

.page-total-value {
  color: #fff;
  font-weight: 600;
  line-height: 20px;
  font-size: 20px;
  letter-spacing: -0.05em;

  margin-bottom: 5px;
}

/**
 * Logo
 */
.logo {
  background: url('../images/zloadrlogo-white.png') no-repeat;
  width: 240px;
  height: 83.04px;
  margin: auto;
  text-indent: -9999px;
  display: block;
}

/**
 * Initial Header
 */
.header-initial .grid {
  height: 240px;
}

.header-alternate {
  height: 35px;
  opacity: .5;
}

/**
 * Intro Header
 */

.header-intro {
  text-align: center;
  padding: 16px;

  .row {
    padding: 0 16px;
  }

  p {
    color: #7EB7C4;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.05em;
    line-height: 19px;

    padding: 16px 0;
  }
}
