/**
 * Traiding History
 */

.traiding-history {
  background: #00697E;

  .traiding-history-header {
    padding: 16px;
  }

  .traiding-history-title {
    font-weight: 600;
    line-height: 20px;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #fff;
  }
}