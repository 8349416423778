/**
 * Our Benefits
 */
.our-benefits {
  padding: 16px;

  h2 {
    color: #2E363C;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.05em;
    padding: 16px 0;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.05em;

    color: #7EB7C4;

    padding: 8px 0 16px;
  }

  p {
    color: #2E363C;
    font-size: 16px;
    letter-spacing: -0.05em;
    line-height: 19px;
  }

  .our-benefits-item {
    padding: 16px 0;
  }

  .our-benefits-img {
    text-align: center;
    padding-bottom: 16px;
  }

}
