/**
 * List with details
 */

.list-details {
  padding: 12px 0 40px;

  .list-details-item:first-child {
    border-top: 1px solid #F4F5F5;
  }
}

.modal-body {
  .list-details {
    padding: 0;
  }
}

.list-details-item {
  border-bottom: 1px solid #F4F5F5;
  padding: 15px 0;
}

.list-details-title {
  color: #979797;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  display: block;
  margin-bottom: 2px;
}

.list-details-value {
  color: #2E363C;
  font-size: 16px;
  letter-spacing: -0.05em;

  display: block;
}

.ld-content {
  padding: 16px 0;
}

.ld-header-icon {
  width: 70px;
  text-align: center;
  float: left;
}

.ld-header .section-title {
  margin-left: 70px;
}

.ld-header {
  margin-top: 16px;
}

.ld-header:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.ld-btn {
  width: 253px;
  margin: 0 auto 7px;
}

.ld-note {
  padding: 8px 0 16px;

  p {
    color: #979797;
    font-size: 12px;
    letter-spacing: -0.05em;
  }
}

/**
 * Popup-dropdown
 */
.popup-dropdown {
  .list-details-item {
    .checkbox-label {
      color: #fff;
    }

    .checkbox-field {

      .checkmark {
        background-color: transparent;
      }

      input:checked~.checkmark {
        background-color: #55A0B2;
      }
    }

    padding: 16px;
    border-color: #19788d;
  }

  .list-details .list-details-item:first-child {
    border-color: #19788d;
  }

}
