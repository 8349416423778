/**
 * Top Rates
 */
.top-rates {
  margin: 15px 0 30px;
}

.top-rates-item {
  color: #fff;
}

.top-rates-item+.top-rates-item {
  margin-top: 8px;
}

.top-rates-link {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.05em;

  display: block;
  padding: 15px 10px 13px 49px;
  border-radius: 4px;

  background: #00E2D0 url('../images/icon-small-top-cryptocurrencies-by-due-diligence.png') no-repeat 8px center;
}

.top-rates-icon-exchanges {
  background-image: url('../images/icon-small-top-cryptocurrency-exchanges.png');
  background-position-x: 8px;
}

.top-rates-icon-icos {
  background-image: url('../images/icon-small-top-icos-by-due-diligence.png');
  background-position-x: 13px;
}

.top-rates-icon-preicos {
  background-image: url('../images/icon-small-top-preicos-by-due-diligence.png');
  background-position-x: 10px;
}

.top-rates-icon-airdrop {
  background-image: url('../images/icon-small-top-airdrop-coins-by-due-diligence.png');
  background-position-x: 10px;
}

/**
 * Top Rates Alternative
 */
.top-rates-alt {
  margin: 20px -8px;
}

.top-rates-items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;
}

.tra-item {
  flex: 1 1 50%;
  padding: 8px;
  max-width: 50%;

  @include unselectable;
}

.tra-link {
  color: #fff;
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.05em;
  display: block;

  height: 164px;

  padding: 100px 16px 14px;

  background: #00697E url('../images/icon-top-cryptocurrencies-by-due-diligence.png') no-repeat center 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  &.icon-top-cryptocurrency-exchanges {
    background-image: url('../images/icon-top-cryptocurrency-exchanges.png');
  }

  &.icon-top-icos-by-due-diligence {
    background-image: url('../images/icon-top-icos-by-due-diligence.png');
  }

  &.icon-top-preicos-by-due-diligence {
    background-image: url('../images/icon-top-preicos-by-due-diligence.png');
  }

  &.icon-top-airdrop-coins-by-due-diligence {
    background-image: url('../images/icon-top-airdrop-coins-by-due-diligence.png');
  }
}
