/**
 * Popup Filter
 */

.popup-filter {
  background: #00697e;
  position: fixed;
  top: 0;
  padding: 0;
}

.popup-filter-header {
  position: relative;
  padding: 0 0 18px;

  .btn-close {
    position: absolute;
    width: 22px;
    height: 22px;
    right: 0;
    top: 0;
    background: url('../images/btn-close-filter.png') no-repeat center;
    cursor: pointer;
  }
}

.popup-filter-title {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 22px;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.0241176em;

  color: #7EB7C4;
}

.filter-item-title {
  color: #00E2D0;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.05em;

  padding: 0 0 12px;
}

.filter-item {
  margin: 0 0 16px;
}

.filter-fieldset {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.popup-filter-footer {
  display: flex;
}
