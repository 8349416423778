/**
 * Variables
 */

$header-main-gradient: linear-gradient(0deg, #00697E, #00697E), linear-gradient(180deg, #00697E -2.65%, #144A55 100%);
$header-gradient: #00697E linear-gradient(180deg, #00697E 0%, #7EB7C4 100%);

$body-min-height: 667px;

$max-grid-width: 414px; // 375px;

$font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, sans-serif;

