/**
 * Steps Indication
 */

.steps {
  position: relative;
  padding: 10px 0;

  .steps-status {
    color: #00E2D0;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    padding-bottom: 3px;
    display: block;
    position: relative;
  }

  .steps-title {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -9px;

      width: 16px;
      height: 2px;
      background: #00E2D0;
    }
  }

  .steps-indicator-list {
    position: absolute;
    bottom: 12px;
    right: 0;
    display: flex;

    li + li {
      margin-left: 8px;
    }
  }

  .steps-indicator {
    width: 8px;
    height: 8px;
    display: block;

    border-radius: 8px;
    opacity: 0.3;
    background: #00E2D0;

    &.active {
      opacity: 1;
    }
  }

}
