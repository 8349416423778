/**
 * Toolbar Buttons
 */
 .action-list .btn {
  text-indent: -9999px;
  background-repeat: no-repeat;
}

.btn-toolbar-back {
  left: 8px;

  width: 13px;
  height: 24px;

  background: url('../images/btn-toolbar-back.png');
}

.btn-toolbar-filter {
  right: 42px;

  width: 26px;
  height: 24px;

  background: url('../images/btn-toolbar-filter.png');
}

.btn-toolbar-notifications {
  right: 8px;

  width: 25px;
  height: 25px;

  background: url('../images/btn-toolbar-notifications.png');
}

.btn-toolbar-export {
  right: 8px;

  width: 27px;
  height: 27px;

  background: url('../images/btn-toolbar-export.png');
}


.btn-toolbar-sort {
  right: 10px;

  width: 24px;
  height: 24px;

  background: url('../images/btn-toolbar-sort.png');
}

.btn-toolbar-logo-zloadr {
  left: 8px;

  width: 23px;
  height: 23px;

  background: url('../images/btn-toolbar-logo-zloadr.png');
}

.btn-toolbar-edit {
  right: 10px;

  width: 26px;
  height: 25px;

  background: url('../images/btn-toolbar-edit.png');
}

.btn-toolbar-apply {
  right: 10px;

  width: 27px;
  height: 23px;

  background: url('../images/btn-toolbar-apply.png');
}
