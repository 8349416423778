/**
 * Article
 */

.article {
  h2 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #2E363C;
  }

  h3 {
    color: #00697E;
    font-weight: 600;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: -0.05em;

    margin-bottom: 16px;
  }

  h3 ~ h3 {
    margin-top: 24px;
  }

  p {
    color: #2E363C;
    font-size: 16px;
    letter-spacing: -0.05em;
    line-height: 19px;

    margin-top: 16px;
  }
}
