/**
 * Who We Are
 */

.who-we-are {
  padding: 16px 16px 47px 16px;
  background: url('../images/bg-about-us.png') no-repeat;

  -webkit-background-size: cover;
  background-size: cover;

  position: relative;

  &:before {
    content: '';

    position: absolute;

    width: 80px;
    height: 4px;

    left: 50%;
    margin-left: -40px;

    bottom: 32px;

    background: #00E2D0;
  }

  h2 {
    color: #00E2D0;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.05em;
    padding: 8px 0 16px;
  }

  p {
    color: #fff;
    line-height: 19px;
    font-size: 14px;
    letter-spacing: -0.05em;
  }

  p + p {
    margin-top: 8px;
  }
}
