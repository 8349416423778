/**
 * View Wallet
 */
.view-wallet {
  padding: 0 0 16px;
}

.view-wallet-title {
  color: #2E363C;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.05em;
  padding-bottom: 8px;
}

.view-wallet-subtitle {
  color: #979797;
  font-size: 16px;
  letter-spacing: -0.05em;
  padding-bottom: 8px;
}
