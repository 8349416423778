/**
 * Dropdown Popup
 */
.popup-dropdown {
  background: #00697E;

  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  z-index: 1;

  display: none;

  &.active {
    display: block;
  }
}

/**
 * Dropdown List
 */
.dropdown-list {
  >li {
    border-bottom: 1px solid rgba(126, 183, 196, .1);

    &:first-child {
      border-top: 1px solid rgba(126, 183, 196, .1);
    }
  }
}

.dropdown-list-item {
  color: #7EB7C4;
  font-size: 20px;
  letter-spacing: -0.05em;
  display: block;
  padding: 16px;

  &:hover,
  &:focus {
    color: #fff;
  }
}

/**
 * Dropdown Footer
 */
.dropdown-footer {
  padding: 24px;
}
