/**
 * Payments List
 */
.list-payments {

  margin: 16px 0;

  > li:first-child {
    border-top: 1px solid #F4F5F5;
  }

}

.pl-item {
  border-bottom: 1px solid #F4F5F5;
}

.pl-link {
  display: flex;
  align-items: center;

  padding: 16px;

  position: relative;

  &:before {
    content: '';
    background: url('../images/btn-back.png') no-repeat;
    position: absolute;
    right: 16px;
    top: 50%;

    margin-top: -12px;

    width: 13px;
    height: 24px;
  }


  .radio-field {
    padding-left: 0;
    padding-right: 32px;
    width: 100%;
  }

  .radio-field .checkmark {
    right: 0;
    top: 50%;
    left: auto;
    margin-top: -16px;
  }

}

.pl-radio {
  &:before {
    background: none;
  }
}

.pl-image {
  margin: 0 8px 0 0;
}

.pl-title {
  color: #979797;
  font-size: 12px;
  letter-spacing: -0.05em;
  padding-bottom: 3px;
  text-transform: uppercase;

  display: block;
}

.pl-subtitle {
  color: #2E363C;
  font-size: 16px;
  letter-spacing: -0.05em;
}

.pl-title-settings {
  color: #7EB7C4;
  text-transform: uppercase;
}