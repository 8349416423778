/**
 * Section Team
 */
.team {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  span {
    display: block;
  }

  img {
    width: 100%;
  }
}

.team-person {
  width: 50%;
}

.ts-content {
  padding: 16px 8px 0;
}

.team-person-photo {
  position: relative;

  .link-icon {
    position: absolute;
    bottom: 3px;
    right: 0;

    background: url('../images/team/icon-in.png') no-repeat;
    width: 32px;
    height: 32px;
  }
}

.team-person-info {
  text-align: center;

  background: #00697E;
  border-bottom: 4px solid #144A55;
  margin-top: -3px;

  padding: 16px 0 8px;
}

.team-person-name {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.0241176em;
  padding-bottom: 5px;
}

.team-person-position {
  color: #00E2D0;
  line-height: 22px;
  font-size: 12px;
  letter-spacing: -0.0241176em;
  text-transform: uppercase;
}

.team-header {
  align-self: center;
  flex: 1 100%;

  h3 {
    color: #2E363C;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.05em;
    text-align: center;

    padding: 32px 0 0;
  }

}