/**
 * Chart: Exchange Rates Comparison
 * 15 Exchange Due Diligence Report
 */

.ico-amounts {
  background: #00697E;
  padding: 16px;

  .ico-amounts-title {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    padding: 8px 0 32px;
  }
}

.ico-amounts-content {

}

.ico-amounts-list {

}

.ico-amounts-item {
  display: inline-block;
  width: 30%;
  margin-bottom: 8px;
}

.ico-amounts-legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ico-amounts-legend-label {
  display: block;

  width: 32px;
  height: 32px;

  margin-right: 8px;

  border-radius: 50%;

  background: #94EAE3;
}

.ico-amounts-legend-label-2 {
  background: #00E2D0;
}

.ico-amounts-legend-label-3 {
  background: #2FCFC2;
}


.ico-amounts-legend-label-4 {
  background: #2DC1B5;
}


.ico-amounts-legend-label-5 {
  background: #32B8AD;
}

.ico-amounts-legend-title {
  color: #fff;
  font-size: 16px;
  letter-spacing: -0.05em;
}
