/**
 * Sort
 */

.sort-item {
  background: url('../images/icon-sort.png') no-repeat 20px center;
  cursor: move;
  cursor: -webkit-grabbing;
  padding-left: 50px;

  @include unselectable;

  &.active,
  &:hover {
    color: #fff;
    background-image: url('../images/icon-sort-active.png');
  }
}
