/**
 * Grid
 */

.grid {
  max-width: $max-grid-width;
  margin: 0 auto;
}


/* Horizontal / Vertical centering */
.main .grid.grid-center,
.grid-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.row {
  display: flex;
  margin: 0 -8px;
}

.row-wrap {
  flex-wrap: wrap;
}

.row-space {
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.row-vertical-center {
  align-items: center;
  margin: 0;

  .card-header-details {
    margin-left: 10px;
  }
}

.row-right-end {
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;

  height: 100%;

  span {
    text-align: right;
    width: 100%;
    margin-top: 5px;
  }
}

.col {
  padding: 16px;
  position: relative;
}

.col-3 {
  width: calc(100% / 4);
  margin: 0 8px;
}

.col-4 {
  width: 20%;
  margin: 0 8px;
}

.col-5 {
  width: 35%;
  margin: 0 8px;
}

.col-6 {
  width: 50%;
  margin: 0 8px;
}

.col-7 {
  width: 65%;
  margin: 0 8px;
}

.col-8 {
  width: 80%;
  margin: 0 8px;
}

.col-right {
  display: flex;
  justify-content: flex-end;
}

.col-center {
  display: flex;
  align-items: center;
}

.col-reset-bottom {
  padding-bottom: 0;
}
