/**
 * Payments Line
 */
.payments-line {
  background: #F1F7F8;
  .list-details-title {
    color: #7EB7C4;
    margin-bottom: 5px;
  }
}
