/**
 * Latest News
 */
.latest-news {
  padding-bottom: 20px;
}

.latest-news-header {
  padding-bottom: 12px;
}

.latest-news-item {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  background: #fff;
}

.latest-news-item+.latest-news-item {
  margin-top: 10px;
}

.ln-article-photo {
  width: 100%;
}

.ln-article-header {
  padding: 7px 16px 14px;
}

.ln-article-title {
  color: #00697E;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: -0.05em;
}

.ln-article-details {
  color: #979797;
  font-size: 12px;
  letter-spacing: -0.05em;
  margin-top: 7px;
}

.ln-article-content {
  padding: 0 16px 50px;

  p {
    color: #2E363C;
    font-size: 14px;
    letter-spacing: -0.05em;
  }

  p + p {
    margin-top: 20px;
  }
}

.latest-news-footer {
  padding: 16px 0 0;

  .btn {
    margin: 0 50px;
    width: auto;
  }
}

/**
 * News Category
 */
.news-category {
  color: #00697E;

  font-size: 12px;
  text-align: center;
  letter-spacing: -0.05em;

  position: absolute;
  padding: 4px 20px;

  right: 0;
  top:0;

  min-width: 72px;

  border-radius: 0px 4px;
  background: #fff;

  &.news-category-mining {
    color: #fff;
    background: #7EB7C4;
  }

  &.news-category-markets {
    color: #fff;
    background: #00697E;
  }

  &.news-category-bounties {
    color: #fff;
    background: #00E2D0;
  }
}
