/**
 * Questionnaire
 */

.questionnaire-item {
  border-top: 1px solid #F4F5F5;
  padding: 16px 16px 8px;

  .question {
    color: #2E363C;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.05em;

    position: relative;
    padding: 0 0 10px 40px;

    min-height: 50px;

    &:before {
      content: 'Q';

      text-align: center;
      line-height: 30px;

      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;

      border-radius: 50%;
      border: 1px solid #2E363C;
    }
  }

  .answer {
    color: #A4A4A4;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.05em;

    position: relative;
    padding: 0 0 10px 40px;

    p + p {
      margin-top: 10px;
    }

    &:before {
      content: 'A';

      color: #D9D9D9;
      text-align: center;
      line-height: 30px;

      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;

      border-radius: 50%;
      border: 1px solid #D9D9D9;
    }
  }

}
