/**
 * Datagrid
 */

.datagrid {
  margin: 0 16px;
  overflow: hidden;
}

.dl-row {
  display: flex;
  border-bottom: 1px solid #F4F5F5;
  padding: 10px 0;
}

.dl-col-1 {
  width: 125px;
}

.dl-col-2 {
  width: 155px;
}


.dl-col {
  color: #2E363C;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: -0.05em;

  b {
    font-weight: 600;
  }

  span {
    line-height: 36px;
  }
}

.dl-footer  {
  background: #F4F5F5;

  .dl-col {
    font-weight: 600;
  }
}

/**
 * Card Datagrid
 */
.card-datagrid {
  padding: 0 16px 8px;

  .dl-col-1 {
    color: #979797;
    font-size: 12px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  .dl-col {
    word-break: break-all;
  }

  .dl-row:first-child {
    border-top: 1px solid #F4F5F5;
  }
}
