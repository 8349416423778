/**
 * Video Styles
 */

.video-area {
  margin: 24px 0 32px;
}

.video-js {
  width: 100%;
  height: 100%;
  min-height: 194px;
}

.vjs-big-play-centered .vjs-big-play-button {
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #00E2D0;
  margin: -16px 0 0 -16px;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border: 0;

  background: #00E2D0;
}

.vjs-icon-play::before, .video-js .vjs-big-play-button .vjs-icon-placeholder::before, .video-js .vjs-play-control .vjs-icon-placeholder::before {
  content: "\f101";
  font-size: 20px;
  top: -7px;
}
