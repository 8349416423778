/**
 * Lists
 */

/**
 * Action List
 */
.action-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.action-list>li {
  display: inline-block;
}

.action-list>li>a {
  position: absolute;
}

/**
 * Filter list
 */

.filter-list {
  margin-top: 10px;
  display: flex;

  align-items: center;
  justify-content: center;

  margin: 0 -4px;
}



.filter-list>li>a {
  display: block;
  margin: 0 4px;
}

.fcl-label {
  color: #2E363C;
  font-size: 16px;
  letter-spacing: -0.05em;
  white-space: nowrap;
  margin: 4px 12px 0 0;
  display: block;
}

/**
 * Responsive tweaks
 */
 @media only screen and (max-width: 358px) {
  .fcl-label {
    display: none;
  }
}

/**
 * Card Footer List
 */

.card-footer-list {
  > li {
    color: #979797;
    font-size: 12px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    display: inline-block;

    border-left: 1px solid #ddd;
    padding: 0 10px;

    &:first-child {
      padding-left: 0;
      border-left: 0;
    }

    span {
      color: #7EB7C4;
    }
  }

}

/**
 * Ex. list
 */
.ex-list {
  > li {
    color: #2E363C;
    font-size: 12px;
    letter-spacing: -0.05em;
    padding: 4px 0;

    span {
      color: #7EB7C4;
    }
  }
}

/**
 * Social List
 */
.social-list {
  display: flex;
  margin-top: 10px;

  >li {
    width: calc(100% / 3);
    padding-left: 1px;
    position: relative;
    top: 11px;

    &:first-child {
      padding-left: 0;
    }

    a {
      display: flex;
      justify-content: center;
      align-content: center;

      width: 100%;
      height: 35px;

      background: #00E2D0;

      span {
        align-self: center;
        overflow: hidden;
      }


    }
  }
}