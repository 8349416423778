/**
 * Our Vision
 */
.our-vision {
  background: #00697E;
  padding: 16px;

  h2 {
    color: #00E2D0;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    letter-spacing: -0.05em;

    padding: 16px 0;
  }

  p {
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
}
