/**
 * Summary
 */
.summary {
  border-top: 1px solid #F4F5F5;

  padding: 32px 0;

  b {
    font-weight: 600;
  }

  .summary-val {
    color: #979797;
    font-size: 16px;
    letter-spacing: -0.05em;

    display: block;
  }

}