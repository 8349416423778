/**
 * Wallets Manager
 */

.wallets-manager {
  padding: 0 0 16px;
}

.wm-header {
  padding: 16px;
  background: #144A55;
  position: relative;
}

.wm-title {
  color: #fff;
  font-size: 20px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
}

.wm-list {
  padding: 0 16px;

  > li:last-child {
    border: 0;
  }
}

.wm-item {
  padding: 16px 0;
  border-bottom: 1px solid #F4F5F5;
}

.wm-item-header {
  padding: 0 0 16px;
}

.wm-item-title {
  color: #2E363C;
  font-size: 20px;
  letter-spacing: -0.05em;
  display: inline-block;
}

.wm-details {
  color: #979797;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: right;

  display: block;
  padding-right: 32px;

  background: url('../images/btn-back.png') no-repeat 100% center;
}