/**
 * Select List
 */

.select-list {
  .select-item:first-child {
    .select-link {
      border: 0;
    }
  }
  padding-bottom: 4px;
}

.select-link {
  display: block;
  padding: 10px 16px 15px;
  border-top: 1px solid #F4F5F5;

  &:hover,
  &.active {
    background: #00697E;

    .select-type,
    .select-details {
      color: #fff;
    }
  }
}

.select-icon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.select-type {
  color: #2E363C;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.05em;
  font-feature-settings: 'pnum'on, 'onum'on;
}

.select-label {
  color: #7EB7C4;
  font-size: 16px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
}

.select-title {
  color: #7EB7C4;
  font-size: 16px;
  letter-spacing: -0.05em;
  font-feature-settings: 'pnum'on, 'onum'on;

  display: block;
}

.select-details {
  color: #000;
  font-size: 16px;
  letter-spacing: -0.05em;
  font-feature-settings: 'pnum' on, 'onum' on;
  display: block;
}