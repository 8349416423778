/**
 * Tags
 */

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.tag {
  color: #fff;
  font-size: 12px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  padding: 6px 7px;
  border-radius: 4px;
  background: #199ED8;

  margin: 4px;

  @include unselectable;
}

.tag-warning {
  background: #FE7472;
}

.tag-disabled {
  color: #979797;
  background: #F4F5F5;
}

.tag-info {
  background: #7EB7C4;
}

.tag-success {
  background: #00E2D0;
}

.tag-primary {
  background: #6AC05C;
}

.tag-default {
  background: #00697E;
}

.tag-outline {
  color: #00697E;
  background: transparent;
  border: 1px solid #7EB7C4;
}

.tag-rounded {
  border-radius: 30px;
  padding-left: 14px;
  padding-right: 14px;
}

.tags-section {
  margin: 24px 0 0;
}

.tag-inline {
  display: inline-block;
}
