/**
 * Card with Success Style
 */

.card-success {
  border: 1px solid #00E2D0;
}

.card-top-hint {
  background: #00E2D0;
  border-radius: 4px 4px 0px 0px;
  padding: 8px;
  display: flex;
  align-items: center;

  img {
    margin-right: 3px;
  }

  p {
    color: #00697E;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }
}