/**
 * Progressbar
 */

.progressbar {
  height: 8px;
  position: relative;
  width: 100%;

  background: #F4F5F5;
  border-radius: 4px;
}

.progressbar-value {
  background: linear-gradient(90deg, #7EB7C4 0%, #00E2D0 100%);
  border-radius: 4px;
  height: 8px;
  width: 10%;
}

.progressbar-legend {
  color: #2E363C;
  font-size: 10px;
  letter-spacing: -0.05em;
  padding-bottom: 5px;

  span {
    color: #979797;
  }
}
