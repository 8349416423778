/**
 * Payment Methods
 */

.payment-methods {

  .card-link {
    color: #2E363C;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.05em;

    min-height: 160px;
    display: flex;

    align-items: center;

    padding: 0 0 0 32px;

    img {
      margin-right: 34px;
    }
  }

}

/**
 * Responsive tweaks
 */
@media only screen and (max-width: 358px) {
  .payment-methods .card-link {
    img {
      margin-right: 16px;
    }
  }
}
