/**
 * Upload Documents
 */

.upload-documents {

  padding: 8px 16px;

  h3 {
    color: #00697E;
    font-size: 20px;
    letter-spacing: -0.05em;

    padding: 16px 0;
  }

}

.upload-documents-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction:row;

  margin: -8px;
}

.upload-documents-item {
  padding: 8px;
  width: 50%;
  height: 164px;
}

.upload-documents-link {
  position: relative;
  display: block;

  width: 100%;
  height: 100%;

  border-radius: 4px;
  overflow: hidden;

  background: #F4F5F5;
  border: 1px dashed #D9D9D9;


  img {
    width: 100%;
    height: 100%;
  }

  &.active {

    border: 1px dashed transparent;


    &:before {
      content: ' ';


      width: 16px;
      height: 16px;

      top: 8px;
      right: 8px;

      background: url('../images/btn-clear.png') no-repeat center center;
    }

    &:after {
      border: 0;
      background: none;
    }
  }

  &:before {
    content: 'Upload Scan';

    color: #D9D9D9;
    font-size: 16px;
    letter-spacing: -0.00869565em;
    text-align: center;

    position: absolute;
    width: 100%;
    bottom: 16px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: url('../images/icon-upload.png') no-repeat center center;
  }
}
